import React from 'react';

const NoteDisplay = ({ notes, onDelete, isLoading, noteDisplay }) => {
  const handleDelete = async (id) => {
    try {
      await onDelete(id); // Await the onDelete function, assuming it returns a Promise
    } catch (error) {
      console.error('Error deleting note:', error);
      alert('Failed to delete note. Please try again.');
    }
  };

  return (
    <div>
    {!noteDisplay ? (
      <div>

        {notes.map((note) => (
          <div key={note._id} className="note">
            <p>Name: {note.title}</p>
            <p>Email: {note.content}</p>
            <button onClick={() => handleDelete(note._id)} disabled={isLoading}>
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        ))}
      </div>
    ) : null}
  </div>
  );
};

export default NoteDisplay;

// SubmitForm.jsx
import React, { useState } from 'react';

const SubmitForm = ({ onNoteSubmitted, isLoading, isSubmitted }) => {
  const [formData, setFormData] = useState({ title: '', content: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.title && formData.content) {
      onNoteSubmitted(formData); // Call the parent function to submit the note
      setFormData({ title: '', content: '' }); // Reset form data after submission
    } else {
      alert('Please fill in all fields.');
    }
  };

  return (
    <div className='form'>
      <h1>/* join the 
        <br>
        </br>IN WAVES crew mailing list */</h1>
      {!isSubmitted && (
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          placeholder="Name"
          value={formData.title}
          onChange={handleChange}
          required
        />
        <input
           type="text"
          name="content"
          placeholder="Email"
          value={formData.content}
          onChange={handleChange}
          required
        ></input>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'joining...' : 'join'}
        </button>
      </form>
      )}

      {isSubmitted && (
        <div>
          Thanks for signing up!
        </div>
      )}
    </div>
  );
};

export default SubmitForm;

// Header.jsx
import React from 'react';
import '../App.css'; // Import the CSS file for styling
import axios from 'axios';

import tiktokLogo from '../tiktok-512.png';

import instalogo from '../instagram-512.png';
import discordlogo from '../discord-2-512.png';

const Header = () => {
  return (
    <div className="header">
      {/* TikTok logo linking to tiktok.com/@inwavesband */}
      <a href="https://www.tiktok.com/@inwavesband" target="_blank" rel="noopener noreferrer">
        <img src= {tiktokLogo} alt="TikTok Logo" className="logo" />
      </a>
      
      {/* Instagram logo linking to instagram.com/inwavesband */}
      <a href="https://www.instagram.com/inwavesband" target="_blank" rel="noopener noreferrer">
        <img src={instalogo} alt="Instagram Logo" className="logo" />
      </a>
      <a href="https://discord.gg/du6RkPB6w3" target="_blank" rel="noopener noreferrer">
        <img src={discordlogo} alt="Discord Logo" className="logo" />
      </a>
    </div>
  );
}

export default Header;




// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import SubmitForm from './components/submitForm';
import NoteDisplay from './components/notedisplay';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'; // Import Router components from React Router
import axios from  'axios'
import HomePage from './components/HomePage'; // Import the HomePage component
import Members from './components/Members'; // Import the HomePage component
import Header from './components/Header';



function App() {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({ title: '', content: '' });

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get('http://localhost:5000/notes');
        setNotes(response.data);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    fetchNotes();
  }, []);


  const handleNoteSubmitted = async (newNote) => {
    try {
      setIsLoading(true);
      // Make POST request to add the new note
      const postedNote = await axios.post('http://localhost:5000/notes', newNote);
      setIsSubmitted(true);
      // Update notes state with the new note
      setNotes([...notes, postedNote.data.note]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error submitting note:', error);
      alert('Failed to submit note. Please try again.');
      setIsLoading(false);
    }
  };

  const handleNoteDelete = async (id) => {
    try {
      setIsLoading(true);
      // Make DELETE request to delete the note from the server
      await axios.delete(`http://localhost:5000/notes/${id}`);
      // Update notes state by filtering out the deleted note
      setNotes(notes.filter((note) => note._id !== id));
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting note:', error);
      alert('Failed to delete note. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <Router>
      <div className="App">

        <Header /> {/* Render the Header component */}
      
        {/* <nav>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/members">Members</Link>
            </li>
          </ul>
        </nav> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tyuchaf" element={<Members />} />
        </Routes>
  
      </div>
    </Router>
  );
}


export default App;
















// HomePage.jsx
import React, { useState, useEffect } from 'react';
import SubmitForm from './submitForm';
import NoteDisplay from './notedisplay';

import axios from 'axios';

const Members = () => {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({ title: '', content: '' });

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await axios.get('http://localhost:5000/notes');
        setNotes(response.data);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    fetchNotes();
  }, []);



  const handleNoteDelete = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`http://localhost:5000/notes/${id}`);
      setNotes(notes.filter((note) => note._id !== id));
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting note:', error);
      alert('Failed to delete note. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <div>

      <NoteDisplay notes={notes} onDelete={handleNoteDelete} isLoading={isLoading} />
    </div>
  );
}

export default Members;
